<template>
    <section class="w-100">
        <div class="row mx-0 bg-white br-t-12 py-3 align-items-center">
            <div class="col-auto">
                <el-select v-model="id_tienda" filterable :placeholder="`selecciona un ${$config.vendedor}`" size="small" class="w-100" :disabled="!id_cedis" clearable @change="onChange()">
                    <el-option
                    v-for="item in tiendas"
                    :key="item.id"
                    :label="`${$config.vendedor}: ${item.propietario_nombre}`"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-auto" style="width:185px;">
                <el-select v-model="rango" placeholder="Seleccionar" size="small">
                    <el-option
                    v-for="item in rangos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>

            <div class="col-auto px-0 mx-2" style="width:248px;">
                <div class="date-picker-edit">
                    <el-date-picker
                    v-model="fecha"
                    class="w-100"
                    size="small"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    :disabled="disable_fecha"
                    popper-class="date-picker-edit-popper"
                    type="daterange"
                    unlink-panels
                    start-placeholder="Fecha inicio"
                    end-placeholder="Fecha fin"
                    />
                </div>
            </div>
            <div v-loading="loading" class="col-auto">
                <div class="btn-general bg-general br-8 f-14 text-white px-3" @click="mostrarPedidos">
                    Mostrar informe
                </div>
            </div>
            <div class="col-auto">
                <el-checkbox v-model="tipo_cedis" class="check-dark" @change="cargarDatosMapa(false)">
                    Cedis
                </el-checkbox>
                <el-checkbox v-model="tipo_leechero" class="check-dark" @change="cargarDatosMapa(false)">
                    {{ $config.vendedor }}
                </el-checkbox>
                <el-checkbox v-model="tipo_pedidos" class="check-dark" @change="cargarDatosMapa(false)">
                    Pedidos
                </el-checkbox>
            </div>
        </div>
        <mapa-google ref="GoogleMaps" height="calc(100vh - 203px)" />
    </section>
</template>

<script>
import Entregas from '~/services/entregas/mapas'
import moment from 'moment'
import {mapGetters} from 'vuex'

export default {
    data(){
        return{
            loading: false,
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Ultima semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Ultimo mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Ultimo año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            model: {
                fecha: null
            },
            tiendas:[],
            id_tienda: null,
            tipo_cedis: true,
            tipo_leechero: true,
            tipo_pedidos: true,
            locations_cedis: [],
            locations_leecheros: [],
            locations_pedidos: []

        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        }),
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = moment().startOf('day').toDate()
                this.fecha[1] = moment().endOf('day').toDate()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').startOf('day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').endOf('day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;
            default:
                break;
            }
        },
        id_cedis(val){
            this.tiendas = []
            this.id_tienda = null
            this.mostrarPedidos()
        },

    },
    mounted(){
        this.mostrarPedidos()
    },
    methods:{
        async mostrarPedidos(load = true){
            try {
                this.loading = true
                const params = {
                    fecha: this.fecha,
                    id_cedis: this.id_cedis,
                    id_tienda: this.id_tienda
                }
                const {data} = await Entregas.listar_entrega_clientes(params)
                this.tiendas = data.tiendas
                var cedis_format = data.cedis.map(e => ({ lat: e.latitud, lng: e.longitud, icon: '/img/icons/cedis_mapa.svg'})
                )
                var pedidos_format = data.pedidos.map(e => ({ lat: e.latitud, lng: e.longitud, icon: '/img/icons/maps_pedidos.svg', label: '1'}))
                var filtro_tiendas = this.tiendas.filter(e => e.id === this.id_tienda )
                var tiendas_format = filtro_tiendas.map(e => ({ lat: e.latitud, lng: e.longitud, icon: '/img/icons/tienda-mapa.svg'}))
                this.locations_cedis = cedis_format
                this.locations_pedidos = pedidos_format
                this.locations_leecheros = tiendas_format
                this.cargarDatosMapa(load)


            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }

        },
        onChange(){
            this.mostrarPedidos(false)

        },
        cargarDatosMapa(load = false){
            let locations = []

            if(this.tipo_cedis) locations = [...locations, ...this.locations_cedis]
            if(this.tipo_leechero) locations = [...locations, ...this.locations_leecheros]
            if(this.tipo_pedidos) locations = [...locations, ...this.locations_pedidos]
            if (load){
                this.$refs.GoogleMaps.initMapMarkerClusterer(locations, true, false)
            } else {
                this.$refs.GoogleMaps.addNewMarkers(locations, true, false)
            }

        }
    }
}
</script>

